import { useEffect, useState } from "react";
import { dtos, ui } from "../common";

interface Props {
    settings: dtos.BillingSettings;
    onChanged: (settings: dtos.BillingSettings) => void;
}

interface ItemProps {
    item: dtos.BillingItem;
    onChanged: (item: dtos.BillingItem) => void;
}

interface ItemTableRow {
    name: string;
    item: dtos.BillingItem;
    hasBase: boolean;
    hasUnit: boolean;
    hasRaw: boolean;
    onChanged: (item: Partial<dtos.BillingItem>) => void;
}

export default function BillingSettings(props: Props) {
    const [items, setItems] = useState<ItemTableRow[]>([]);

    function onChanged(update: Partial<dtos.BillingSettings>) {
        props.onChanged(new dtos.BillingSettings({ ...props.settings, ...update }));
    }

    useEffect(function init() {
        if (!props.settings) {
            setItems([]);
            return;
        }

        const s = props.settings;
        setItems([{
            name: "Base (Twilio Monthly Fee)",
            hasUnit: false,
            hasBase: true,
            hasRaw: true,
            item: s.base,
            onChanged: item => onChanged({ base: new dtos.BillingItem({ ...s.base, ...item }) })
        }, {
            name: "Local Numbers",
            hasUnit: true,
            hasBase: false,
            hasRaw: true,
            item: s.localNumbers,
            onChanged: item => onChanged({ localNumbers: new dtos.BillingItem({ ...s.localNumbers, ...item }) })
        }, {
            name: "Toll Free Numbers",
            hasUnit: true,
            hasBase: false,
            hasRaw: true,
            item: s.tollFreeNumbers,
            onChanged: item => onChanged({ tollFreeNumbers: new dtos.BillingItem({ ...s.tollFreeNumbers, ...item }) })
        }, {
            name: "Inbound Voice Calls",
            hasUnit: true,
            hasBase: false,
            hasRaw: true,
            item: s.inboundVoiceCalls,
            onChanged: item => onChanged({ inboundVoiceCalls: new dtos.BillingItem({ ...s.inboundVoiceCalls, ...item }) })
        }, {
            name: "Outbound Voice Calls",
            hasUnit: true,
            hasBase: false,
            hasRaw: true,
            item: s.outboundVoiceCalls,
            onChanged: item => onChanged({ outboundVoiceCalls: new dtos.BillingItem({ ...s.outboundVoiceCalls, ...item }) })
        }, {
            name: "Inbound Faxes",
            hasUnit: true,
            hasBase: false,
            hasRaw: true,
            item: s.inboundFaxes,
            onChanged: item => onChanged({ inboundFaxes: new dtos.BillingItem({ ...s.inboundFaxes, ...item }) })
        }, {
            name: "Outbound Faxes",
            hasUnit: true,
            hasBase: false,
            hasRaw: true,
            item: s.outboundFaxes,
            onChanged: item => onChanged({ outboundFaxes: new dtos.BillingItem({ ...s.outboundFaxes, ...item }) })
        }, {
            name: "Inbound SMS Messages",
            hasUnit: true,
            hasBase: false,
            hasRaw: true,
            item: s.inboundSmsMessages,
            onChanged: item => onChanged({ inboundSmsMessages: new dtos.BillingItem({ ...s.inboundSmsMessages, ...item }) })
        }, {
            name: "Outbound SMS Messages",
            hasUnit: true,
            hasBase: false,
            hasRaw: true,
            item: s.outboundSmsMessages,
            onChanged: item => onChanged({ outboundSmsMessages: new dtos.BillingItem({ ...s.outboundSmsMessages, ...item }) })
        }, {
            name: "AI Insight Minutes",
            hasUnit: true,
            hasBase: false,
            hasRaw: false,
            item: s.aiInsights,
            onChanged: item => onChanged({ aiInsights: new dtos.BillingItem({ ...s.aiInsights, ...item }) })
        }]);
    }, [props.settings])

    return <ui.Table dataSource={items} pagination={false} rowKey="name">
        <ui.Table.Column title="Description" render={(text, rec: ItemTableRow) => <span>{rec.name}</span>} />
        <ui.Table.Column title={<span>Base Cost <ui.Popover content={<span>This is the base cost that is charged regardless of quantity - you can think of this as a flat monthly fee.</span>}><i className="fa fa-info-circle" /></ui.Popover></span>} render={(text, rec: ItemTableRow) => <>
            {rec.hasBase && <ui.InputNumber value={rec.item.baseCost} onChange={ev => rec.onChanged({ baseCost: ev })} />}
        </>} />
        <ui.Table.Column title={<span>Raw Multiplier <ui.Popover content={<span>This is multiplied by the individual cost of the billable when calculating the total.<br />For example, every phone call on Twilio has a different cost depending on duration, the call's cost is multiplied against this value to determine the final cost.<br />A value of 1 would be passthrough pricing, A value of 2 would be double the Twilio cost.</span>}><i className="fa fa-info-circle" /></ui.Popover></span>} render={(text, rec: ItemTableRow) => <>
            {rec.hasRaw && <ui.InputNumber value={rec.item.rawUnitMultiplier} onChange={ev => rec.onChanged({ rawUnitMultiplier: ev })} />}
        </>} />
        <ui.Table.Column title={<span>Unit Cost <ui.Popover content={<span>This is multiplied by the quantity of each billable to determine a unit price. <br />In general either this or raw multipliers will be used but not together.<br />For example, to charge $1.00 per call regardless of Twilio rate, you would set this to 1.00 and the Raw Multiplier to 0.</span>}><i className="fa fa-info-circle" /></ui.Popover></span>} render={(text, rec: ItemTableRow) => <>
            {rec.hasUnit && <ui.InputNumber value={rec.item.unitCost} onChange={ev => rec.onChanged({ unitCost: ev })} />}
        </>} />
        <ui.Table.Column title={<span>Unit Allowance <ui.Popover content={<span>The number of units that are free before applying the Unit Cost. <br />In general either unit pricing or raw multipliers will be used but not together.<br />For example, to charge $1.00 per call regardless of Twilio rate with 100 calls free, you would set Unit Cost to 1.00, the Raw Multiplier to 0, and the Unit Allowance to 100</span>}><i className="fa fa-info-circle" /></ui.Popover></span>} render={(text, rec: ItemTableRow) => <>
            {rec.hasUnit && <ui.InputNumber value={rec.item.allowance} onChange={ev => rec.onChanged({ allowance: ev })} />}
        </>} />
    </ui.Table>
}